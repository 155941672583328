import { Typography } from "@mui/material";
import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Properties = Loadable(lazy(() => import("src/pages/property/index")));

const property = [
    {
        path: "properties",
        children: [
            {
                index: true,
                element: <Properties />,
            },
            {
                path: "new",
                element: <Typography variant='h1'>Create</Typography>,
            },
            {
                path: ":id",
                element: <Typography variant='h1'>Edit</Typography>,
            },
        ],
    },
];

export default property;
