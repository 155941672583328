import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Bills = Loadable(lazy(() => import("src/pages/bills/index")));

const bills = [
    {
        path: "bills",
        children: [
            {
                index: true,
                element: <Bills />,
            },
        ],
    },
];

export default bills;
